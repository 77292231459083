import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Hero from '../components/home/Hero';
import Plans from '../components/home/Plans';
import Quote from '../components/home/Quote';
import Audience from '../components/home/Audience';
import How from '../components/home/How';
import ExitIntentModal from '../components/modals/ExitIntentModal';
import Footer from '../components/layout/Footer';

type IndexProps = {
  data: IndexQueryData
};
export default function Index({ data }:IndexProps) {
  return <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content="With Restream you can create beautiful videos and multistream them to your favorite social channels. Launch a live show or stream an in-person event." />
      <title>Live video made simple. Get started free | {data.site.siteMetadata.name}</title>
    </Helmet>
    <Hero />
    <Plans/>
    <Quote />
    <How />
    <Audience />
    <Footer />
  </>;
}

type IndexQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};
export const query = graphql`
  query IndexQuery{
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;
